import { useEffect, useMemo, useState } from 'react';

import { IconSet } from '@postscript/components';
import { PERMISSIONS } from 'components/account/AccountView/users/constants';
import {
  BCC,
  DATA_LABS,
  PS_LABS,
  PS_PAY,
  SHOPPER_RESPONSES_TO_CONVERSATIONS,
  SMS_SALES_CONVERSATIONS,
  SMS_SALES_NAV,
  SSAI,
  USAGE_BILLING_ENABLED,
} from 'components/admin/utils/feature-flags';
import { FEATURE_KEYS } from 'components/billing/common/constants';
import { useShopperBillingStatus } from 'components/billing/modules/plans/hooks/useShopperBillingStatus';
import { IS_PRODUCT_CATALOG_ENABLED } from 'components/brand/product/constants';
import { NavMenuItem } from 'components/navigation/NavMenu';
import { ROUTE_POPUPS_BLOCK, SUBROUTE_LIST } from 'components/popups/constants';
import UnresolvedNotificationsBadge from 'components/responses/UnresolvedNotificationsBadge';
import { useShopperStatus } from 'hooks/useShopperStatus';
import { ShopperStatusContextType } from 'providers/shopperStatusProvider';
import { isSalesRoute } from 'utils/isSalesRoute';
import { usePSLabs } from '../../../controllers/contexts/labsFeatures';
import { useInfinityTestingBillingStatus } from '../../billing/modules/plans/hooks/useInfinityTestingBillingStatus';
import { BrandCenterRoutes } from '../../brand/types';
import { ShopperTrialStatusTooltipContent } from '../../shopper/components/ShopperStatusBadge';

export const useMenuConfig = (): NavMenuItem[] => {
  const shopperStatusContext = useShopperStatus();
  const {
    isEligible: isEligibleForInfinityTesting,
    hasProduct: hasInfinityTestingProduct,
  } = useInfinityTestingBillingStatus();
  const { isEligible: isEligibleForShopper } = useShopperBillingStatus();

  const [menuConfig, setMenuConfig] = useState<NavMenuItem[]>([]);
  const { hasLabsFlag } = usePSLabs();
  const hasBccEnabled = hasLabsFlag(BCC);

  const getAnalyticsLink = (url: string) =>
    window.location.pathname.includes('/analytics')
      ? `${url}${window.location.search}`
      : url;

  const isSales = useMemo(() => isSalesRoute(), []);

  const getBaseMenu = (
    shopperStatus: ShopperStatusContextType,
  ): NavMenuItem[] => {
    const showBrandCenterMenuItem =
      hasBccEnabled ||
      isEligibleForInfinityTesting ||
      hasInfinityTestingProduct ||
      isEligibleForShopper ||
      shopperStatus.hasShopperFeatureEnabled;

    return [
      {
        id: 'dashboard',
        label: 'Dashboard',
        icon: IconSet.Dashboard,
        isLoading: false,
        link: '/',
        ariaLabelledBy: 'nav-dashboard-title',
      },
      {
        id: 'sms-sales',
        label: 'SMS Sales',
        icon: IconSet.Dollar,
        isLoading: false,
        ariaLabelledBy: 'nav-sales-title',
        labsFeatureFlag: SMS_SALES_NAV,
        subMenu: [
          {
            link: '/sms-sales/performance',
            label: 'Performance',
            id: 'sms-sales-perforance',
          },
          {
            labsFeatureFlag: SMS_SALES_CONVERSATIONS,
            link: '/sms-sales/conversations',
            label: 'Conversations',
            id: 'sms-sales-conversations',
          },
          {
            link: '/sms-sales/conversation-intelligence?filters=HAS_SUMMARY',
            label: 'Conversation Intelligence',
            id: 'sms-sales-conversation-intelligence',
          },
        ],
      },
      {
        id: 'messaging',
        label: 'Messaging',
        icon: IconSet.Message,
        isLoading: false,
        ariaLabelledBy: 'nav-messaging-title',
        notification: UnresolvedNotificationsBadge(true),
        subMenu: [
          {
            link: '/campaigns',
            label: 'Campaigns',
            id: 'messaging-campaigns',
          },
          {
            link: '/automations',
            label: 'Automations',
            id: 'messaging-automations',
          },
          { link: '/segments', label: 'Segments', id: 'messaging-segments' },
          {
            link: '/responses',
            label: 'Responses',
            id: 'messaging-responses',
            notification: UnresolvedNotificationsBadge(false),
            labsFeatureFlag: SHOPPER_RESPONSES_TO_CONVERSATIONS,
            featureFlagOptOut: true,
          },
          {
            link: `/conversations${
              shopperStatusContext.isLive ? '' : '?resolved=false'
            }`,
            label: 'Conversations',
            id: 'messaging-conversations',
            labsFeatureFlag: SHOPPER_RESPONSES_TO_CONVERSATIONS,
          },
        ],
      },
      {
        id: 'acquisition',
        label: 'Acquisition',
        icon: IconSet.UsersPlus,
        isLoading: false,
        ariaLabelledBy: 'nav-list-growth-title',
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
        subMenu: [
          {
            id: 'acquisition-popups',
            label: 'Legacy Popups',
            link: '/popups',
          },
          {
            id: 'acquisition-popups-block-popups',
            label: 'Popups',
            link: `${ROUTE_POPUPS_BLOCK}${SUBROUTE_LIST}`,
            tag: 'Beta',
          },
          {
            id: 'acquisition-keywords',
            label: 'Keywords',
            link: '/keywords',
          },
          {
            id: 'acquisition-list-growth',
            label: 'List Growth Tools',
            link: '/grow',
          },
          {
            id: 'acquisition-checkout',
            label: 'Checkout Collection',
            link: '/checkout',
          },
        ],
      },
      {
        id: 'analytics',
        label: 'Data & Insights',
        icon: IconSet.Leaderboard,
        isLoading: shopperStatus.isLoading,
        ariaLabelledBy: 'nav-analytics-title',
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
        subMenu: [
          {
            link: (): string => getAnalyticsLink(`/analytics/messages`),
            label: 'Messages',
            id: 'analytics-messages',
          },
          {
            link: (): string => getAnalyticsLink(`/analytics/subscribers`),
            label: 'Subscribers',
            id: 'analytics-subscribers',
          },
          {
            link: '/analytics/ai',
            label: 'Infinity Testing',
            id: 'analytics-ai',
            labsFeatureFlag: BCC,
          },
          ...(shopperStatus.hasShopperFeatureEnabled
            ? [
                {
                  link: (): string => `/analytics/shopper`,
                  label: 'Shopper',
                  id: 'shopper-trends',
                },
              ]
            : []),
          {
            link: '/trends',
            label: 'Trends',
            id: 'trends',
            gatedFeatureKey: FEATURE_KEYS.omni_trends,
          },
          {
            link: '/account/reports',
            label: 'Reports',
            id: 'analytics-reports',
          },
        ],
      },
      ...(showBrandCenterMenuItem
        ? [
            {
              id: 'brand-center',
              label: 'Brand Center',
              icon: IconSet.SettingsSliders,
              isLoading: shopperStatus.isLoading,
              ariaLabelledBy: 'nav-brand-center-title',
              subMenu: [
                {
                  link: BrandCenterRoutes.VOICE,
                  label: 'Voice and Tone',
                  id: 'voice-and-tone',
                },
                ...(shopperStatus.hasShopperFeatureEnabled ||
                isEligibleForShopper
                  ? [
                      {
                        link: BrandCenterRoutes.SHOPPER,
                        label: 'Shopper',
                        id: 'shopper',
                        tooltip:
                          shopperStatus.trialDaysRemaining > 0
                            ? {
                                id: 'shopper-trial-warning',
                                place: 'right' as const,
                                size: 'small' as const,
                                icon: {
                                  component: IconSet.Information,
                                  size: 'medium' as const,
                                },
                                content: (
                                  <ShopperTrialStatusTooltipContent
                                    withCancellationGuidance={false}
                                  />
                                ),
                              }
                            : undefined,
                      },
                    ]
                  : []),
                ...(IS_PRODUCT_CATALOG_ENABLED
                  ? [
                      {
                        link: BrandCenterRoutes.PRODUCTS,
                        label: 'Product Catalog',
                        id: 'product-catalog',
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      {
        id: 'pspay',
        label: 'Text-to-Buy',
        icon: IconSet.PSTextToBuy,
        isLoading: false,
        ariaLabelledBy: 'nav-pspay-title',
        labsFeatureFlag: PS_PAY,
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
        subMenu: [
          {
            link: '/pspay/manage',
            label: 'Branding',
            id: 'pspay-manage',
          },
        ],
      },
      {
        id: 'integrations',
        label: 'Integrations',
        icon: IconSet.Apps,
        isLoading: false,
        ariaLabelledBy: 'nav-integrations-title',
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
        subMenu: [
          { link: '/integrations', label: 'All', id: 'integrations-all' },
          {
            link: 'https://help.postscript.io/hc/en-us/articles/12311826727067-Set-Up-Your-Fondue-Integration',
            label: 'Fondue',
            id: 'integrations-fondue',
            tag: 'New',
          },
        ],
      },
      {
        id: 'account',
        label: 'Account',
        icon: IconSet.Settings2,
        isLoading: false,
        ariaLabelledBy: 'nav-account-title',
        subMenu: [
          {
            link: '$selectShop',
            label: 'Select Shop',
            id: 'account-select-shop',
          },
          {
            link: '/account/settings',
            label: 'Shop Settings',
            id: 'account-settings',
            restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
          },
          {
            link: '/profile',
            label: 'Profile',
            id: 'profile',
          },
          {
            link: '/account/users',
            label: 'Users',
            id: 'account-users',
            permission: PERMISSIONS.user_read,
            restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
          },
          {
            link: '/account/tracking',
            label: 'Tracking',
            id: 'account-tracking',
            restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
          },
          {
            link: '/account/plan',
            label: 'Plan',
            id: 'account-plan',
            featureFlag: USAGE_BILLING_ENABLED,
            featureFlagOptOut: true,
            restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
          },
          {
            link: '/account/billing',
            label: 'Billing',
            id: 'account-billing',
            featureFlag: USAGE_BILLING_ENABLED,
            featureFlagOptOut: true,
            restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
          },
          {
            link: '/billing',
            label: 'Billing',
            id: 'account-usage-billing',
            featureFlag: USAGE_BILLING_ENABLED,
            permission: PERMISSIONS.billing_read,
            restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
          },
          {
            link: '/account/api',
            label: 'API',
            id: 'account-api',
            restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
          },
          {
            link: '/account/ps-labs',
            label: 'PS Labs',
            id: 'account-ps-labs',
            featureFlag: PS_LABS,
            restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
          },
          {
            link: '/account/contact-card',
            label: 'Shop Contact Card',
            id: 'account-contact-card',
            restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
          },
          {
            link: '/subscriber-list-uploads',
            label: 'Subscriber List Uploads',
            id: 'subscriber-list-uploads',
          },
          { link: '$logOut', label: 'Logout', id: 'account-logout' },
        ],
      },
      {
        id: 'admin',
        label: 'Postscript Admin',
        icon: IconSet.Key,
        isLoading: false,
        ariaLabelledBy: 'nav-admin-title',
        subMenu: [
          {
            link: '/account-overview',
            label: 'Account Overview',
            id: 'admin-account-overview',
          },
          {
            link: '/shopper-config',
            label: 'Shopper Config',
            id: 'admin-shopper-config',
            labsFeatureFlag: SSAI,
          },
          {
            link: '/admin/templates',
            label: 'Templates',
            id: 'admin-templates',
          },
          {
            link: '/admin/billing',
            label: 'Billing',
            id: 'admin-billing',
            featureFlag: USAGE_BILLING_ENABLED,
            featureFlagOptOut: true,
          },
          {
            link: '/admin/messaging-status',
            label: 'Messaging',
            id: 'admin-messaging',
          },
          {
            link: '/admin/announcements',
            label: 'Service Announcements',
            id: 'admin-announcements',
          },
          {
            link: '/admin/ps-labs',
            label: 'PS Labs',
            id: 'admin-ps-labs',
          },
          {
            link: '/admin/partners',
            label: 'Partners',
            id: 'admin-partners',
          },
          {
            link: '/admin/data-labs',
            label: 'Data Labs',
            id: 'data-labs',
            labsFeatureFlag: DATA_LABS,
          },
          {
            link: '/omni',
            label: 'Omni Sandbox',
            id: 'omni',
          },
          {
            link: '/admin/list-management',
            label: 'List Management',
            id: 'admin-list-management',
          },
        ],
      },
    ];
  };

  const getSalesMenu = (): NavMenuItem[] => {
    return [
      {
        id: 'inbox',
        label: 'My Prospects',
        icon: IconSet.Message,
        isLoading: false,
        ariaLabelledBy: 'nav-sales-conversations-title',
        link: '/workspace/my-prospects',
      },
      {
        id: 'conversation-intelligence',
        label: 'Conversation Intelligence',
        icon: IconSet.TwoWayConvo,
        isLoading: false,
        ariaLabelledBy: 'nav-sales-conversation-intelligence',
        link: '/workspace/conversation-intelligence',
      },
      {
        id: 'marketing',
        label: 'Marketing',
        icon: IconSet.DoorExit,
        isLoading: false,
        ariaLabelledBy: 'nav-sales-marketing-title',
        link: '/',
      },
    ];
  };

  const buildMenu = (
    shopperStatus: ShopperStatusContextType,
    isSales: boolean,
  ) => (isSales ? getSalesMenu() : getBaseMenu(shopperStatus));

  useEffect(() => {
    setMenuConfig(buildMenu(shopperStatusContext, isSales));
  }, [shopperStatusContext, isSales]);

  return menuConfig;
};
