/* eslint-disable import/no-named-as-default */
import { GlobalModalProvider } from 'components/GlobalModal/globalModal';
import { WebSocketProvider } from 'components/responses/hooks/useWebSocket';
import { AgentActionsProvider } from 'controllers/contexts/agentActions';
import { AnnouncementsProvider } from 'controllers/contexts/announcements';
import { AutomationTemplatesProvider } from 'controllers/contexts/automationTemplates';
import { FullScreenEditorProvider } from 'controllers/contexts/fullScreenEditor';
import { SystemEventVisibilityProvider } from 'controllers/contexts/systemEventVisibility';
import { AcceptedTOSProvider } from 'controllers/contexts/termsOfService';
import PropTypes from 'prop-types';
import { ShopperStatusProvider } from 'providers';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BillingProvider, UserContext } from '../../controllers/contexts';
import { AutomationsProvider } from '../../controllers/contexts/automations';
import { FeatureFlagsProvider } from '../../controllers/contexts/featureFlags';
import { LabsFeatureProvider as LabsFeatureFlagsProvider } from '../../controllers/contexts/labsFeatures';
import { LegacyGlobalBannersProvider } from '../../controllers/contexts/legacyGlobalBanners';
import { MMSStatusProvider } from '../../controllers/contexts/mmsStatus';
import { NotesProvider } from '../../controllers/contexts/notes';
import { PartnersProvider } from '../../controllers/contexts/partners';
import { ReferralsProvider } from '../../controllers/contexts/referrals';
import Notifications from '../account/notifications';
import { UsageBillingProvider } from '../billing/context/usageBilling';
import { ActivePollingLinksProvider } from '../brand/hooks/useActivePollingLinks';
import { GlobalBannersProvider } from '../GlobalBanners/globalBanners';
import ModalContainer from '../layout/ModalContainer';

/**
 * Wrapper for injecting global resources (via context api) that are
 * used throught the application e.g. users
 *
 * @component
 * @example
 * return (
 *   <GlobalAuthenticatedContexts>
 *     <ExampleAuthenticatedComponents />
 *   </GlobalAuthenticatedContexts>
 * )
 */
const GlobalAuthenticatedContexts = ({
  user,
  shops,
  isFetchingShops,
  getUserInfo,
  updateUserInfo,
  updateShops,
  children,
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <UserContext.Provider
        value={{
          user,
          shops,
          isFetchingShops,
          getUserInfo,
          updateUserInfo,
          updateShops,
        }}
      >
        <FeatureFlagsProvider>
          <LabsFeatureFlagsProvider>
            <WebSocketProvider>
              <AcceptedTOSProvider>
                <BillingProvider user={user}>
                  <NotesProvider>
                    <AutomationTemplatesProvider>
                      <AutomationsProvider>
                        <PartnersProvider>
                          <LegacyGlobalBannersProvider>
                            <ShopperStatusProvider>
                              <GlobalModalProvider>
                                <GlobalBannersProvider>
                                  <AnnouncementsProvider>
                                    <UsageBillingProvider>
                                      <MMSStatusProvider>
                                        <ReferralsProvider>
                                          <FullScreenEditorProvider>
                                            <SystemEventVisibilityProvider>
                                              <AgentActionsProvider>
                                                <>
                                                  <Notifications
                                                    shopId={user.shop_id}
                                                  />
                                                  <ActivePollingLinksProvider>
                                                    <ModalContainer>
                                                      {children}
                                                    </ModalContainer>
                                                  </ActivePollingLinksProvider>
                                                </>
                                              </AgentActionsProvider>
                                            </SystemEventVisibilityProvider>
                                          </FullScreenEditorProvider>
                                        </ReferralsProvider>
                                      </MMSStatusProvider>
                                    </UsageBillingProvider>
                                  </AnnouncementsProvider>
                                </GlobalBannersProvider>
                              </GlobalModalProvider>
                            </ShopperStatusProvider>
                          </LegacyGlobalBannersProvider>
                        </PartnersProvider>
                      </AutomationsProvider>
                    </AutomationTemplatesProvider>
                  </NotesProvider>
                </BillingProvider>
              </AcceptedTOSProvider>
            </WebSocketProvider>
          </LabsFeatureFlagsProvider>
        </FeatureFlagsProvider>
      </UserContext.Provider>
    </DndProvider>
  );
};

GlobalAuthenticatedContexts.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  user: PropTypes.any,
  shops: PropTypes.array,
  isFetchingShops: PropTypes.bool,
  getUserInfo: PropTypes.func,
  updateUserInfo: PropTypes.func,
  updateShops: PropTypes.func,
};

GlobalAuthenticatedContexts.defaultProps = {
  user: false,
  shops: [],
  isFetchingShops: false,
  getUserInfo: () => {},
  updateUserInfo: () => {},
  updateShops: () => {},
};

export default GlobalAuthenticatedContexts;
