export enum BrandingLinkCategory {
  SOCIAL_MEDIA = 'social media',
  PRODUCT_CATALOG = 'product catalog',
  BRANDING = 'branding',
  POLICIES = 'policies',
  FAQ = 'faq',
  SUPPORT = 'support',
  GUIDES_QUIZZES = 'guides & quizzes',
  SALES_EVENTS = 'sales events',
  OTHER = 'other',
}

export const SOURCE_CATEGORIES = [
  { label: 'Social media', value: 'social media' },
  { label: 'Product catalog', value: 'product catalog' },
  { label: 'Branding', value: 'branding' },
  { label: 'Policies', value: 'policies' },
  { label: 'FAQ', value: 'faq' },
  { label: 'Support', value: 'support' },
  { label: 'Guides & Quizzes', value: 'guides & quizzes' },
  { label: 'Sales events', value: 'sales events' },
  { label: 'Other', value: 'other' },
];

export const brandingStatusKey = 'bcc_has_shop_branding';
export const brandingKey = 'bcc_shop_branding';
export const previewMessagesKey = 'bcc_preview_messages';
export const inpirationMessagesKey = 'bcc_im_messages';
export const fileSourcesKey = 'bcc_file_sources';
export const linkSourcesKey = 'bcc_link_sources';
export const brandingLinkSource = 'bcc_link_source';
export const initAIGeneratedBrandingKey = 'bcc_init_ai_generated_branding';
