import {
  RecurringFee,
  RecurringFeeTypes,
} from 'components/billing/common/types';
import { useGetRecurringFees } from 'components/billing/context/useBilling';
import { isEndDateBeforeStartDate, isStartDateInFuture } from '../helpers';

export const useProductTrialStatus = (
  feeType: RecurringFeeTypes,
  planId?: number,
) => {
  const { data: feeData, isFetched: feeDataFetched } = useGetRecurringFees({
    type__eq: feeType.toString(),
    product_plan_id__eq: planId,
  });

  if (!feeDataFetched || !feeData?.length) {
    return {
      onProductFreeTrial: false,
      hasExpiredProductFreeTrial: false,
    };
  }

  const hasExpiredProductFreeTrial = feeData.some((fee) => {
    return isEndDateBeforeStartDate(fee.endDate, fee.startDate);
  });

  const onProductFreeTrial = feeData.some((fee) => {
    return (
      isStartDateInFuture(fee.startDate) &&
      // Exclude soft-deleted fees
      !isEndDateBeforeStartDate(fee.endDate, fee.startDate)
    );
  });

  const trialPlatformFee = feeData
    .filter((fee: RecurringFee) => {
      return isStartDateInFuture(fee.startDate);
    })
    .sort((a: RecurringFee, b: RecurringFee) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    })[0];

  const trialEndDate = trialPlatformFee?.startDate;
  const trialProductPlanId = trialPlatformFee?.productPlanId;

  return {
    onProductFreeTrial,
    hasExpiredProductFreeTrial,
    trialEndDate: trialEndDate || null,
    trialProductPlanId,
  };
};
