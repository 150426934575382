import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { api } from '../../../controllers/network/apiClient';
import {
  BrandingLinkCategory,
  brandingLinkSource,
  linkSourcesKey,
} from '../sources/constants';
import { BrandingLinkSource } from '../types';

export type StartPollingFn = (link: BrandingLinkSource) => void;

type PollingContextType = {
  activeLinks: Map<number, string>;
  startPolling: StartPollingFn;
  stopPolling: (id: number) => void;
  isPolling: (id: number) => boolean;
};

const ActivePollingLinksContext = createContext<PollingContextType | null>(
  null,
);

export function ActivePollingLinksProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [activeLinks, setActiveLinks] = useState<Map<number, string>>(
    new Map(),
  );

  const startPolling = (link: BrandingLinkSource) => {
    if (
      link.active &&
      link.category === BrandingLinkCategory.FAQ &&
      link.deletedAt === null
    ) {
      setActiveLinks((prev) => new Map(prev).set(link.id, link.nonceId ?? ''));
    }
  };

  const stopPolling = (id: number) => {
    setActiveLinks((prev) => {
      const newMap = new Map(prev);
      newMap.delete(id);
      return newMap;
    });
  };

  const isPolling = (id: number) => activeLinks.has(id);

  return (
    <ActivePollingLinksContext.Provider
      value={{ activeLinks, startPolling, stopPolling, isPolling }}
    >
      {children}
    </ActivePollingLinksContext.Provider>
  );
}

export function useActivePollingLinks(): PollingContextType {
  const ctx = useContext(ActivePollingLinksContext);
  if (!ctx) {
    throw new Error(
      'useActivePollingLinks must be used within ActivePollingLinksProvider',
    );
  }
  return ctx;
}

export const THIRTY_SECONDS = 30_000;

export function usePollBrandingLinkSource(id: number) {
  const queryClient = useQueryClient();
  const startTimeRef = useRef<number | null>(null);

  const result = useQuery<BrandingLinkSource>({
    queryKey: [brandingLinkSource, id],
    queryFn: async () => {
      const data = await api.get(`/v2/branding/sources/links/${id}`);
      /* Adding refetchedAt timestamp to always yield a unique object, thus triggering effect */
      return { ...data, refetchTime: Date.now() };
    },
    refetchInterval: 3000,
  });

  useEffect(() => {
    if (result.isSuccess && result.data) {
      if (!startTimeRef.current) {
        startTimeRef.current = Date.now();
      }

      // Patch the links list cache with the updated link.
      queryClient.setQueryData<BrandingLinkSource[]>(
        [linkSourcesKey],
        (existing) =>
          existing?.map((link) =>
            link.id === result.data.id ? result.data : link,
          ) ?? [],
      );
    }
  }, [result.isSuccess, result.data, queryClient]);

  return { ...result, startTimeRef };
}
