import { API_HOST, INTERNAL_API_HOST } from 'utils/envVars';

const apiHost = API_HOST;
// TODO: Remove `internalApiHost` and swap values with apiHost
const internalApiHost = INTERNAL_API_HOST || apiHost;

const Constants = {
  apiHost,
  internalApiHost,
  inboundPhone: {
    label: '+1 (720) 605-1331',
    value: '17206051331',
  },
  shortCode: {
    label: '29071',
    value: '29071',
  },
};

export const PS_PAY_LIFECYCLE_STATUSES = {
  PENDING: {
    label: 'Pending',
    value: 'PENDING',
    color: 'secondary',
    description: 'Waiting for customer payment information',
  },
  AWAITING_CONFIRMATION: {
    label: 'Confirming',
    value: 'AWAITING_CONFIRMATION',
    color: 'primary',
    description: 'Waiting for customer to confirm their order',
  },
  CONFIRMED: {
    label: 'Error Placing Order',
    value: 'CONFIRMED',
    color: 'danger',
    description:
      'Error placing order with Shopify. Please reach out to customer support',
  },
  FULFILLED: {
    label: 'Placed',
    value: 'FULFILLED',
    color: 'success',
    description: 'The order has been placed in Shopify',
  },
  CANCELLED: {
    label: 'Cancelled',
    value: 'CANCELLED',
    color: 'light',
    description: 'The order has been cancelled',
  },
  REFUNDED: {
    label: 'Refunded',
    value: 'REFUNDED',
    color: 'dark',
    description: 'The order has been refunded',
  },
  PAYMENT_FAILED: {
    label: 'Payment Failed',
    value: 'PAYMENT_FAILED',
    color: 'danger',
    description:
      'The customer’s order wasn’t placed because their payment failed',
  },
};

export const getDefaultTerms = (hasUpdatedComplianceText: boolean) =>
  hasUpdatedComplianceText
    ? '*By providing your number and clicking the button, you agree to receive recurring auto-dialed marketing SMS (including cart reminders; AI content; artificial or prerecorded voices) and our TERMS OF SERVICE (including arbitration). Consent is not required to purchase. Msg & data rates may apply. Msg frequency varies. Reply HELP for help; STOP to opt-out. View PRIVACY POLICY.'
    : '*I agree to receive recurring automated marketing text messages (e.g. cart reminders) at the phone number provided. Consent is not a condition to purchase. Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. View our Terms of Service and Privacy Policy.';

export const US_STATES_WITH_CODES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District Of Columbia', value: 'DC' },
  { label: 'Federated States Of Micronesia', value: 'FM' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Guam', value: 'GU' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Palau', value: 'PW' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virgin Islands', value: 'VI' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'Washington D.C.', value: 'DC' },
  { label: 'Washington State', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const US_STATES = [
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Alaska', value: 'Alaska' },
  { label: 'American Samoa', value: 'American Samoa' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'District Of Columbia', value: 'District Of Columbia' },
  {
    label: 'Federated States Of Micronesia',
    value: 'Federated States Of Micronesia',
  },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Guam', value: 'Guam' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Idaho', value: 'Idaho' },
  { label: 'Illinois', value: 'Illinois' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Marshall Islands', value: 'Marshall Islands' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Montana', value: 'Montana' },
  { label: 'Nebraska', value: 'Nebraska' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'New York', value: 'New York' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Palau', value: 'Palau' },
  { label: 'Pennsylvania', value: 'Pennsylvania' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'Rhode Island', value: 'Rhode Island' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'Tennessee', value: 'Tennessee' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Virgin Islands', value: 'Virgin Islands' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington D.C.', value: 'Washington D.C.' },
  { label: 'Washington State', value: 'Washington State' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'Wyoming', value: 'Wyoming' },
];

export const COUNTRY_CODES_WITH_NAMES = [
  {
    countryName: 'Afghanistan',
    countryCode: 'AF',
  },
  {
    countryName: 'Albania',
    countryCode: 'AL',
  },
  {
    countryName: 'Algeria',
    countryCode: 'DZ',
  },
  {
    countryName: 'American Samoa',
    countryCode: 'AS',
  },
  {
    countryName: 'Andorra',
    countryCode: 'AD',
  },
  {
    countryName: 'Angola',
    countryCode: 'AO',
  },
  {
    countryName: 'Anguilla',
    countryCode: 'AI',
  },
  {
    countryName: 'Antarctica',
    countryCode: 'AQ',
  },
  {
    countryName: 'Antigua and Barbuda',
    countryCode: 'AG',
  },
  {
    countryName: 'Argentina',
    countryCode: 'AR',
  },
  {
    countryName: 'Armenia',
    countryCode: 'AM',
  },
  {
    countryName: 'Aruba',
    countryCode: 'AW',
  },
  {
    countryName: 'Australia',
    countryCode: 'AU',
  },
  {
    countryName: 'Austria',
    countryCode: 'AT',
  },
  {
    countryName: 'Azerbaijan',
    countryCode: 'AZ',
  },
  {
    countryName: 'Bahamas',
    countryCode: 'BS',
  },
  {
    countryName: 'Bahrain',
    countryCode: 'BH',
  },
  {
    countryName: 'Bangladesh',
    countryCode: 'BD',
  },
  {
    countryName: 'Barbados',
    countryCode: 'BB',
  },
  {
    countryName: 'Belarus',
    countryCode: 'BY',
  },
  {
    countryName: 'Belgium',
    countryCode: 'BE',
  },
  {
    countryName: 'Belize',
    countryCode: 'BZ',
  },
  {
    countryName: 'Benin',
    countryCode: 'BJ',
  },
  {
    countryName: 'Bermuda',
    countryCode: 'BM',
  },
  {
    countryName: 'Bhutan',
    countryCode: 'BT',
  },
  {
    countryName: 'Bolivia, Plurinational State of',
    countryCode: 'BO',
  },
  {
    countryName: 'Bolivia',
    countryCode: 'BO',
  },
  {
    countryName: 'Bosnia and Herzegovina',
    countryCode: 'BA',
  },
  {
    countryName: 'Botswana',
    countryCode: 'BW',
  },
  {
    countryName: 'Bouvet Island',
    countryCode: 'BV',
  },
  {
    countryName: 'Brazil',
    countryCode: 'BR',
  },
  {
    countryName: 'British Indian Ocean Territory',
    countryCode: 'IO',
  },
  {
    countryName: 'Brunei Darussalam',
    countryCode: 'BN',
  },
  {
    countryName: 'Brunei',
    countryCode: 'BN',
  },
  {
    countryName: 'Bulgaria',
    countryCode: 'BG',
  },
  {
    countryName: 'Burkina Faso',
    countryCode: 'BF',
  },
  {
    countryName: 'Burundi',
    countryCode: 'BI',
  },
  {
    countryName: 'Cambodia',
    countryCode: 'KH',
  },
  {
    countryName: 'Cameroon',
    countryCode: 'CM',
  },
  {
    countryName: 'Canada',
    countryCode: 'CA',
  },
  {
    countryName: 'Cape Verde',
    countryCode: 'CV',
  },
  {
    countryName: 'Cayman Islands',
    countryCode: 'KY',
  },
  {
    countryName: 'Central African Republic',
    countryCode: 'CF',
  },
  {
    countryName: 'Chad',
    countryCode: 'TD',
  },
  {
    countryName: 'Chile',
    countryCode: 'CL',
  },
  {
    countryName: 'China',
    countryCode: 'CN',
  },
  {
    countryName: 'Christmas Island',
    countryCode: 'CX',
  },
  {
    countryName: 'Cocos (Keeling) Islands',
    countryCode: 'CC',
  },
  {
    countryName: 'Colombia',
    countryCode: 'CO',
  },
  {
    countryName: 'Comoros',
    countryCode: 'KM',
  },
  {
    countryName: 'Congo',
    countryCode: 'CG',
  },
  {
    countryName: 'Congo, the Democratic Republic of the',
    countryCode: 'CD',
  },
  {
    countryName: 'Cook Islands',
    countryCode: 'CK',
  },
  {
    countryName: 'Costa Rica',
    countryCode: 'CR',
  },
  {
    countryName: "Côte d'Ivoire",
    countryCode: 'CI',
  },
  {
    countryName: 'Ivory Coast',
    countryCode: 'CI',
  },
  {
    countryName: 'Croatia',
    countryCode: 'HR',
  },
  {
    countryName: 'Cuba',
    countryCode: 'CU',
  },
  {
    countryName: 'Cyprus',
    countryCode: 'CY',
  },
  {
    countryName: 'Czech Republic',
    countryCode: 'CZ',
  },
  {
    countryName: 'Denmark',
    countryCode: 'DK',
  },
  {
    countryName: 'Djibouti',
    countryCode: 'DJ',
  },
  {
    countryName: 'Dominica',
    countryCode: 'DM',
  },
  {
    countryName: 'Dominican Republic',
    countryCode: 'DO',
  },
  {
    countryName: 'Ecuador',
    countryCode: 'EC',
  },
  {
    countryName: 'Egypt',
    countryCode: 'EG',
  },
  {
    countryName: 'El Salvador',
    countryCode: 'SV',
  },
  {
    countryName: 'Equatorial Guinea',
    countryCode: 'GQ',
  },
  {
    countryName: 'Eritrea',
    countryCode: 'ER',
  },
  {
    countryName: 'Estonia',
    countryCode: 'EE',
  },
  {
    countryName: 'Ethiopia',
    countryCode: 'ET',
  },
  {
    countryName: 'Falkland Islands (Malvinas)',
    countryCode: 'FK',
  },
  {
    countryName: 'Faroe Islands',
    countryCode: 'FO',
  },
  {
    countryName: 'Fiji',
    countryCode: 'FJ',
  },
  {
    countryName: 'Finland',
    countryCode: 'FI',
  },
  {
    countryName: 'France',
    countryCode: 'FR',
  },
  {
    countryName: 'French Guiana',
    countryCode: 'GF',
  },
  {
    countryName: 'French Polynesia',
    countryCode: 'PF',
  },
  {
    countryName: 'French Southern Territories',
    countryCode: 'TF',
  },
  {
    countryName: 'Gabon',
    countryCode: 'GA',
  },
  {
    countryName: 'Gambia',
    countryCode: 'GM',
  },
  {
    countryName: 'Georgia',
    countryCode: 'GE',
  },
  {
    countryName: 'Germany',
    countryCode: 'DE',
  },
  {
    countryName: 'Ghana',
    countryCode: 'GH',
  },
  {
    countryName: 'Gibraltar',
    countryCode: 'GI',
  },
  {
    countryName: 'Greece',
    countryCode: 'GR',
  },
  {
    countryName: 'Greenland',
    countryCode: 'GL',
  },
  {
    countryName: 'Grenada',
    countryCode: 'GD',
  },
  {
    countryName: 'Guadeloupe',
    countryCode: 'GP',
  },
  {
    countryName: 'Guam',
    countryCode: 'GU',
  },
  {
    countryName: 'Guatemala',
    countryCode: 'GT',
  },
  {
    countryName: 'Guernsey',
    countryCode: 'GG',
  },
  {
    countryName: 'Guinea',
    countryCode: 'GN',
  },
  {
    countryName: 'Guinea-Bissau',
    countryCode: 'GW',
  },
  {
    countryName: 'Guyana',
    countryCode: 'GY',
  },
  {
    countryName: 'Haiti',
    countryCode: 'HT',
  },
  {
    countryName: 'Heard Island and McDonald Islands',
    countryCode: 'HM',
  },
  {
    countryName: 'Holy See (Vatican City State)',
    countryCode: 'VA',
  },
  {
    countryName: 'Honduras',
    countryCode: 'HN',
  },
  {
    countryName: 'Hong Kong',
    countryCode: 'HK',
  },
  {
    countryName: 'Hungary',
    countryCode: 'HU',
  },
  {
    countryName: 'Iceland',
    countryCode: 'IS',
  },
  {
    countryName: 'India',
    countryCode: 'IN',
  },
  {
    countryName: 'Indonesia',
    countryCode: 'ID',
  },
  {
    countryName: 'Iran, Islamic Republic of',
    countryCode: 'IR',
  },
  {
    countryName: 'Iraq',
    countryCode: 'IQ',
  },
  {
    countryName: 'Ireland',
    countryCode: 'IE',
  },
  {
    countryName: 'Isle of Man',
    countryCode: 'IM',
  },
  {
    countryName: 'Israel',
    countryCode: 'IL',
  },
  {
    countryName: 'Italy',
    countryCode: 'IT',
  },
  {
    countryName: 'Jamaica',
    countryCode: 'JM',
  },
  {
    countryName: 'Japan',
    countryCode: 'JP',
  },
  {
    countryName: 'Jersey',
    countryCode: 'JE',
  },
  {
    countryName: 'Jordan',
    countryCode: 'JO',
  },
  {
    countryName: 'Kazakhstan',
    countryCode: 'KZ',
  },
  {
    countryName: 'Kenya',
    countryCode: 'KE',
  },
  {
    countryName: 'Kiribati',
    countryCode: 'KI',
  },
  {
    countryName: "Korea, Democratic People's Republic of",
    countryCode: 'KP',
  },
  {
    countryName: 'Korea, Republic of',
    countryCode: 'KR',
  },
  {
    countryName: 'South Korea',
    countryCode: 'KR',
  },
  {
    countryName: 'Kuwait',
    countryCode: 'KW',
  },
  {
    countryName: 'Kyrgyzstan',
    countryCode: 'KG',
  },
  {
    countryName: "Lao People's Democratic Republic",
    countryCode: 'LA',
  },
  {
    countryName: 'Latvia',
    countryCode: 'LV',
  },
  {
    countryName: 'Lebanon',
    countryCode: 'LB',
  },
  {
    countryName: 'Lesotho',
    countryCode: 'LS',
  },
  {
    countryName: 'Liberia',
    countryCode: 'LR',
  },
  {
    countryName: 'Libyan Arab Jamahiriya',
    countryCode: 'LY',
  },
  {
    countryName: 'Libya',
    countryCode: 'LY',
  },
  {
    countryName: 'Liechtenstein',
    countryCode: 'LI',
  },
  {
    countryName: 'Lithuania',
    countryCode: 'LT',
  },
  {
    countryName: 'Luxembourg',
    countryCode: 'LU',
  },
  {
    countryName: 'Macao',
    countryCode: 'MO',
  },
  {
    countryName: 'Macedonia, the former Yugoslav Republic of',
    countryCode: 'MK',
  },
  {
    countryName: 'Madagascar',
    countryCode: 'MG',
  },
  {
    countryName: 'Malawi',
    countryCode: 'MW',
  },
  {
    countryName: 'Malaysia',
    countryCode: 'MY',
  },
  {
    countryName: 'Maldives',
    countryCode: 'MV',
  },
  {
    countryName: 'Mali',
    countryCode: 'ML',
  },
  {
    countryName: 'Malta',
    countryCode: 'MT',
  },
  {
    countryName: 'Marshall Islands',
    countryCode: 'MH',
  },
  {
    countryName: 'Martinique',
    countryCode: 'MQ',
  },
  {
    countryName: 'Mauritania',
    countryCode: 'MR',
  },
  {
    countryName: 'Mauritius',
    countryCode: 'MU',
  },
  {
    countryName: 'Mayotte',
    countryCode: 'YT',
  },
  {
    countryName: 'Mexico',
    countryCode: 'MX',
  },
  {
    countryName: 'Micronesia, Federated States of',
    countryCode: 'FM',
  },
  {
    countryName: 'Moldova, Republic of',
    countryCode: 'MD',
  },
  {
    countryName: 'Monaco',
    countryCode: 'MC',
  },
  {
    countryName: 'Mongolia',
    countryCode: 'MN',
  },
  {
    countryName: 'Montenegro',
    countryCode: 'ME',
  },
  {
    countryName: 'Montserrat',
    countryCode: 'MS',
  },
  {
    countryName: 'Morocco',
    countryCode: 'MA',
  },
  {
    countryName: 'Mozambique',
    countryCode: 'MZ',
  },
  {
    countryName: 'Myanmar',
    countryCode: 'MM',
  },
  {
    countryName: 'Burma',
    countryCode: 'MM',
  },
  {
    countryName: 'Namibia',
    countryCode: 'NA',
  },
  {
    countryName: 'Nauru',
    countryCode: 'NR',
  },
  {
    countryName: 'Nepal',
    countryCode: 'NP',
  },
  {
    countryName: 'Netherlands',
    countryCode: 'NL',
  },
  {
    countryName: 'Netherlands Antilles',
    countryCode: 'AN',
  },
  {
    countryName: 'New Caledonia',
    countryCode: 'NC',
  },
  {
    countryName: 'New Zealand',
    countryCode: 'NZ',
  },
  {
    countryName: 'Nicaragua',
    countryCode: 'NI',
  },
  {
    countryName: 'Niger',
    countryCode: 'NE',
  },
  {
    countryName: 'Nigeria',
    countryCode: 'NG',
  },
  {
    countryName: 'Niue',
    countryCode: 'NU',
  },
  {
    countryName: 'Norfolk Island',
    countryCode: 'NF',
  },
  {
    countryName: 'Northern Mariana Islands',
    countryCode: 'MP',
  },
  {
    countryName: 'Norway',
    countryCode: 'NO',
  },
  {
    countryName: 'Oman',
    countryCode: 'OM',
  },
  {
    countryName: 'Pakistan',
    countryCode: 'PK',
  },
  {
    countryName: 'Palau',
    countryCode: 'PW',
  },
  {
    countryName: 'Palestinian Territory, Occupied',
    countryCode: 'PS',
  },
  {
    countryName: 'Panama',
    countryCode: 'PA',
  },
  {
    countryName: 'Papua New Guinea',
    countryCode: 'PG',
  },
  {
    countryName: 'Paraguay',
    countryCode: 'PY',
  },
  {
    countryName: 'Peru',
    countryCode: 'PE',
  },
  {
    countryName: 'Philippines',
    countryCode: 'PH',
  },
  {
    countryName: 'Pitcairn',
    countryCode: 'PN',
  },
  {
    countryName: 'Poland',
    countryCode: 'PL',
  },
  {
    countryName: 'Portugal',
    countryCode: 'PT',
  },
  {
    countryName: 'Puerto Rico',
    countryCode: 'PR',
  },
  {
    countryName: 'Qatar',
    countryCode: 'QA',
  },
  {
    countryName: 'Réunion',
    countryCode: 'RE',
  },
  {
    countryName: 'Romania',
    countryCode: 'RO',
  },
  {
    countryName: 'Russian Federation',
    countryCode: 'RU',
  },
  {
    countryName: 'Russia',
    countryCode: 'RU',
  },
  {
    countryName: 'Rwanda',
    countryCode: 'RW',
  },
  {
    countryName: 'Saint Helena, Ascension and Tristan da Cunha',
    countryCode: 'SH',
  },
  {
    countryName: 'Saint Kitts and Nevis',
    countryCode: 'KN',
  },
  {
    countryName: 'Saint Lucia',
    countryCode: 'LC',
  },
  {
    countryName: 'Saint Pierre and Miquelon',
    countryCode: 'PM',
  },
  {
    countryName: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
  },
  {
    countryName: 'Saint Vincent & the Grenadines',
    countryCode: 'VC',
  },
  {
    countryName: 'St. Vincent and the Grenadines',
    countryCode: 'VC',
  },
  {
    countryName: 'Samoa',
    countryCode: 'WS',
  },
  {
    countryName: 'San Marino',
    countryCode: 'SM',
  },
  {
    countryName: 'Sao Tome and Principe',
    countryCode: 'ST',
  },
  {
    countryName: 'Saudi Arabia',
    countryCode: 'SA',
  },
  {
    countryName: 'Senegal',
    countryCode: 'SN',
  },
  {
    countryName: 'Serbia',
    countryCode: 'RS',
  },
  {
    countryName: 'Seychelles',
    countryCode: 'SC',
  },
  {
    countryName: 'Sierra Leone',
    countryCode: 'SL',
  },
  {
    countryName: 'Singapore',
    countryCode: 'SG',
  },
  {
    countryName: 'Slovakia',
    countryCode: 'SK',
  },
  {
    countryName: 'Slovenia',
    countryCode: 'SI',
  },
  {
    countryName: 'Solomon Islands',
    countryCode: 'SB',
  },
  {
    countryName: 'Somalia',
    countryCode: 'SO',
  },
  {
    countryName: 'South Africa',
    countryCode: 'ZA',
  },
  {
    countryName: 'South Georgia and the South Sandwich Islands',
    countryCode: 'GS',
  },
  {
    countryName: 'Spain',
    countryCode: 'ES',
  },
  {
    countryName: 'Sri Lanka',
    countryCode: 'LK',
  },
  {
    countryName: 'Sudan',
    countryCode: 'SD',
  },
  {
    countryName: 'Suriname',
    countryCode: 'SR',
  },
  {
    countryName: 'Svalbard and Jan Mayen',
    countryCode: 'SJ',
  },
  {
    countryName: 'Swaziland',
    countryCode: 'SZ',
  },
  {
    countryName: 'Sweden',
    countryCode: 'SE',
  },
  {
    countryName: 'Switzerland',
    countryCode: 'CH',
  },
  {
    countryName: 'Syrian Arab Republic',
    countryCode: 'SY',
  },
  {
    countryName: 'Taiwan, Province of China',
    countryCode: 'TW',
  },
  {
    countryName: 'Taiwan',
    countryCode: 'TW',
  },
  {
    countryName: 'Tajikistan',
    countryCode: 'TJ',
  },
  {
    countryName: 'Tanzania, United Republic of',
    countryCode: 'TZ',
  },
  {
    countryName: 'Thailand',
    countryCode: 'TH',
  },
  {
    countryName: 'Timor-Leste',
    countryCode: 'TL',
  },
  {
    countryName: 'Togo',
    countryCode: 'TG',
  },
  {
    countryName: 'Tokelau',
    countryCode: 'TK',
  },
  {
    countryName: 'Tonga',
    countryCode: 'TO',
  },
  {
    countryName: 'Trinidad and Tobago',
    countryCode: 'TT',
  },
  {
    countryName: 'Trinidad & Tobago',
    countryCode: 'TT',
  },
  {
    countryName: 'Tunisia',
    countryCode: 'TN',
  },
  {
    countryName: 'Turkey',
    countryCode: 'TR',
  },
  {
    countryName: 'Turkmenistan',
    countryCode: 'TM',
  },
  {
    countryName: 'Turks and Caicos Islands',
    countryCode: 'TC',
  },
  {
    countryName: 'Tuvalu',
    countryCode: 'TV',
  },
  {
    countryName: 'Uganda',
    countryCode: 'UG',
  },
  {
    countryName: 'Ukraine',
    countryCode: 'UA',
  },
  {
    countryName: 'United Arab Emirates',
    countryCode: 'AE',
  },
  {
    countryName: 'United Kingdom',
    countryCode: 'GB',
  },
  {
    countryName: 'United States',
    countryCode: 'US',
  },
  {
    countryName: 'United States Minor Outlying Islands',
    countryCode: 'UM',
  },
  {
    countryName: 'Uruguay',
    countryCode: 'UY',
  },
  {
    countryName: 'Uzbekistan',
    countryCode: 'UZ',
  },
  {
    countryName: 'Vanuatu',
    countryCode: 'VU',
  },
  {
    countryName: 'Venezuela, Bolivarian Republic of',
    countryCode: 'VE',
  },
  {
    countryName: 'Venezuela',
    countryCode: 'VE',
  },
  {
    countryName: 'Viet Nam',
    countryCode: 'VN',
  },
  {
    countryName: 'Vietnam',
    countryCode: 'VN',
  },
  {
    countryName: 'Virgin Islands, British',
    countryCode: 'VG',
  },
  {
    countryName: 'Virgin Islands, U.S.',
    countryCode: 'VI',
  },
  {
    countryName: 'Wallis and Futuna',
    countryCode: 'WF',
  },
  {
    countryName: 'Western Sahara',
    countryCode: 'EH',
  },
  {
    countryName: 'Yemen',
    countryCode: 'YE',
  },
  {
    countryName: 'Zambia',
    countryCode: 'ZM',
  },
  {
    countryName: 'Zimbabwe',
    countryCode: 'ZW',
  },
];

export const COUNTRY_CODES = [
  { label: 'US', value: 'US' },
  { label: 'AG', value: 'AG' },
  { label: 'AI', value: 'AI' },
  { label: 'AS', value: 'AS' },
  { label: 'BB', value: 'BB' },
  { label: 'BM', value: 'BM' },
  { label: 'BS', value: 'BS' },
  { label: 'CA', value: 'CA' },
  { label: 'DM', value: 'DM' },
  { label: 'DO', value: 'DO' },
  { label: 'GD', value: 'GD' },
  { label: 'GU', value: 'GU' },
  { label: 'JM', value: 'JM' },
  { label: 'KN', value: 'KN' },
  { label: 'KY', value: 'KY' },
  { label: 'LC', value: 'LC' },
  { label: 'MP', value: 'MP' },
  { label: 'MS', value: 'MS' },
  { label: 'PR', value: 'PR' },
  { label: 'SX', value: 'SX' },
  { label: 'TC', value: 'TC' },
  { label: 'TT', value: 'TT' },
  { label: 'VC', value: 'VC' },
  { label: 'VG', value: 'VG' },
  { label: 'VI', value: 'VI' },
  { label: 'RU', value: 'RU' },
  { label: 'KZ', value: 'KZ' },
  { label: 'EG', value: 'EG' },
  { label: 'ZA', value: 'ZA' },
  { label: 'GR', value: 'GR' },
  { label: 'NL', value: 'NL' },
  { label: 'BE', value: 'BE' },
  { label: 'FR', value: 'FR' },
  { label: 'ES', value: 'ES' },
  { label: 'HU', value: 'HU' },
  { label: 'IT', value: 'IT' },
  { label: 'VA', value: 'VA' },
  { label: 'RO', value: 'RO' },
  { label: 'CH', value: 'CH' },
  { label: 'AT', value: 'AT' },
  { label: 'GB', value: 'GB' },
  { label: 'GG', value: 'GG' },
  { label: 'IM', value: 'IM' },
  { label: 'JE', value: 'JE' },
  { label: 'DK', value: 'DK' },
  { label: 'SE', value: 'SE' },
  { label: 'NO', value: 'NO' },
  { label: 'SJ', value: 'SJ' },
  { label: 'PL', value: 'PL' },
  { label: 'DE', value: 'DE' },
  { label: 'PE', value: 'PE' },
  { label: 'MX', value: 'MX' },
  { label: 'CU', value: 'CU' },
  { label: 'AR', value: 'AR' },
  { label: 'BR', value: 'BR' },
  { label: 'CL', value: 'CL' },
  { label: 'CO', value: 'CO' },
  { label: 'VE', value: 'VE' },
  { label: 'MY', value: 'MY' },
  { label: 'AU', value: 'AU' },
  { label: 'CC', value: 'CC' },
  { label: 'CX', value: 'CX' },
  { label: 'ID', value: 'ID' },
  { label: 'PH', value: 'PH' },
  { label: 'NZ', value: 'NZ' },
  { label: 'SG', value: 'SG' },
  { label: 'TH', value: 'TH' },
  { label: 'JP', value: 'JP' },
  { label: 'KR', value: 'KR' },
  { label: 'VN', value: 'VN' },
  { label: 'CN', value: 'CN' },
  { label: 'TR', value: 'TR' },
  { label: 'IN', value: 'IN' },
  { label: 'PK', value: 'PK' },
  { label: 'AF', value: 'AF' },
  { label: 'LK', value: 'LK' },
  { label: 'MM', value: 'MM' },
  { label: 'IR', value: 'IR' },
  { label: 'SS', value: 'SS' },
  { label: 'MA', value: 'MA' },
  { label: 'EH', value: 'EH' },
  { label: 'DZ', value: 'DZ' },
  { label: 'TN', value: 'TN' },
  { label: 'LY', value: 'LY' },
  { label: 'GM', value: 'GM' },
  { label: 'SN', value: 'SN' },
  { label: 'MR', value: 'MR' },
  { label: 'ML', value: 'ML' },
  { label: 'GN', value: 'GN' },
  { label: 'CI', value: 'CI' },
  { label: 'BF', value: 'BF' },
  { label: 'NE', value: 'NE' },
  { label: 'TG', value: 'TG' },
  { label: 'BJ', value: 'BJ' },
  { label: 'MU', value: 'MU' },
  { label: 'LR', value: 'LR' },
  { label: 'SL', value: 'SL' },
  { label: 'GH', value: 'GH' },
  { label: 'NG', value: 'NG' },
  { label: 'TD', value: 'TD' },
  { label: 'CF', value: 'CF' },
  { label: 'CM', value: 'CM' },
  { label: 'CV', value: 'CV' },
  { label: 'ST', value: 'ST' },
  { label: 'GQ', value: 'GQ' },
  { label: 'GA', value: 'GA' },
  { label: 'CG', value: 'CG' },
  { label: 'CD', value: 'CD' },
  { label: 'AO', value: 'AO' },
  { label: 'GW', value: 'GW' },
  { label: 'IO', value: 'IO' },
  { label: 'AC', value: 'AC' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'RW', value: 'RW' },
  { label: 'ET', value: 'ET' },
  { label: 'SO', value: 'SO' },
  { label: 'DJ', value: 'DJ' },
  { label: 'KE', value: 'KE' },
  { label: 'TZ', value: 'TZ' },
  { label: 'UG', value: 'UG' },
  { label: 'BI', value: 'BI' },
  { label: 'MZ', value: 'MZ' },
  { label: 'ZM', value: 'ZM' },
  { label: 'MG', value: 'MG' },
  { label: 'RE', value: 'RE' },
  { label: 'YT', value: 'YT' },
  { label: 'ZW', value: 'ZW' },
  { label: 'NA', value: 'NA' },
  { label: 'MW', value: 'MW' },
  { label: 'LS', value: 'LS' },
  { label: 'BW', value: 'BW' },
  { label: 'SZ', value: 'SZ' },
  { label: 'KM', value: 'KM' },
  { label: 'SH', value: 'SH' },
  { label: 'TA', value: 'TA' },
  { label: 'ER', value: 'ER' },
  { label: 'AW', value: 'AW' },
  { label: 'FO', value: 'FO' },
  { label: 'GL', value: 'GL' },
  { label: 'GI', value: 'GI' },
  { label: 'PT', value: 'PT' },
  { label: 'LU', value: 'LU' },
  { label: 'IE', value: 'IE' },
  { label: 'IS', value: 'IS' },
  { label: 'AL', value: 'AL' },
  { label: 'MT', value: 'MT' },
  { label: 'CY', value: 'CY' },
  { label: 'FI', value: 'FI' },
  { label: 'AX', value: 'AX' },
  { label: 'BG', value: 'BG' },
  { label: 'LT', value: 'LT' },
  { label: 'LV', value: 'LV' },
  { label: 'EE', value: 'EE' },
  { label: 'MD', value: 'MD' },
  { label: 'AM', value: 'AM' },
  { label: 'BY', value: 'BY' },
  { label: 'AD', value: 'AD' },
  { label: 'MC', value: 'MC' },
  { label: 'SM', value: 'SM' },
  { label: 'UA', value: 'UA' },
  { label: 'RS', value: 'RS' },
  { label: 'ME', value: 'ME' },
  { label: 'XK', value: 'XK' },
  { label: 'HR', value: 'HR' },
  { label: 'SI', value: 'SI' },
  { label: 'BA', value: 'BA' },
  { label: 'MK', value: 'MK' },
  { label: 'CZ', value: 'CZ' },
  { label: 'SK', value: 'SK' },
  { label: 'LI', value: 'LI' },
  { label: 'FK', value: 'FK' },
  { label: 'BZ', value: 'BZ' },
  { label: 'GT', value: 'GT' },
  { label: 'SV', value: 'SV' },
  { label: 'HN', value: 'HN' },
  { label: 'NI', value: 'NI' },
  { label: 'CR', value: 'CR' },
  { label: 'PA', value: 'PA' },
  { label: 'PM', value: 'PM' },
  { label: 'HT', value: 'HT' },
  { label: 'GP', value: 'GP' },
  { label: 'BL', value: 'BL' },
  { label: 'MF', value: 'MF' },
  { label: 'BO', value: 'BO' },
  { label: 'GY', value: 'GY' },
  { label: 'EC', value: 'EC' },
  { label: 'GF', value: 'GF' },
  { label: 'PY', value: 'PY' },
  { label: 'MQ', value: 'MQ' },
  { label: 'SR', value: 'SR' },
  { label: 'UY', value: 'UY' },
  { label: 'CW', value: 'CW' },
  { label: 'BQ', value: 'BQ' },
  { label: 'TL', value: 'TL' },
  { label: 'NF', value: 'NF' },
  { label: 'BN', value: 'BN' },
  { label: 'NR', value: 'NR' },
  { label: 'PG', value: 'PG' },
  { label: 'TO', value: 'TO' },
  { label: 'SB', value: 'SB' },
  { label: 'VU', value: 'VU' },
  { label: 'FJ', value: 'FJ' },
  { label: 'PW', value: 'PW' },
  { label: 'WF', value: 'WF' },
  { label: 'CK', value: 'CK' },
  { label: 'NU', value: 'NU' },
  { label: 'WS', value: 'WS' },
  { label: 'KI', value: 'KI' },
  { label: 'NC', value: 'NC' },
  { label: 'TV', value: 'TV' },
  { label: 'PF', value: 'PF' },
  { label: 'TK', value: 'TK' },
  { label: 'FM', value: 'FM' },
  { label: 'MH', value: 'MH' },
  { label: 'KP', value: 'KP' },
  { label: 'HK', value: 'HK' },
  { label: 'MO', value: 'MO' },
  { label: 'KH', value: 'KH' },
  { label: 'LA', value: 'LA' },
  { label: 'BD', value: 'BD' },
  { label: 'TW', value: 'TW' },
  { label: 'MV', value: 'MV' },
  { label: 'LB', value: 'LB' },
  { label: 'JO', value: 'JO' },
  { label: 'SY', value: 'SY' },
  { label: 'IQ', value: 'IQ' },
  { label: 'KW', value: 'KW' },
  { label: 'SA', value: 'SA' },
  { label: 'YE', value: 'YE' },
  { label: 'OM', value: 'OM' },
  { label: 'PS', value: 'PS' },
  { label: 'AE', value: 'AE' },
  { label: 'IL', value: 'IL' },
  { label: 'BH', value: 'BH' },
  { label: 'QA', value: 'QA' },
  { label: 'BT', value: 'BT' },
  { label: 'MN', value: 'MN' },
  { label: 'NP', value: 'NP' },
  { label: 'TJ', value: 'TJ' },
  { label: 'TM', value: 'TM' },
  { label: 'AZ', value: 'AZ' },
  { label: 'GE', value: 'GE' },
  { label: 'KG', value: 'KG' },
  { label: 'UZ', value: 'UZ' },
];

export const ADDRESS_DEFAULTS = {
  address1: '3370 N Hayden Road',
  address2: '',
  city: 'Scottsdale',
  company: '',
  country: 'United States',
  firstName: 'Tom',
  lastName: 'Smith',
  province: 'Arizona',
  zip: '85251',
};

export const CANADA_PROVINCES = [
  { label: 'Alberta', value: 'AB' },
  { label: 'British Columbia', value: 'BC' },
  { label: 'Manitoba', value: 'MB' },
  { label: 'New Brunswick', value: 'NB' },
  { label: 'Newfoundland and Labrador', value: 'NL' },
  { label: 'Northwest Territories', value: 'NT' },
  { label: 'Nova Scotia', value: 'NS' },
  { label: 'Nunavut', value: 'NU' },
  { label: 'Ontario', value: 'ON' },
  { label: 'Prince Edward Island', value: 'PE' },
  { label: 'Quebec', value: 'QC' },
  { label: 'Saskatchewan', value: 'SK' },
  { label: 'Yukon', value: 'YT' },
];

export const AUSTRALIA_TERRITORIES = [
  { label: 'New South Wales', value: 'NSW' },
  { label: 'Queensland', value: 'QLD' },
  { label: 'South Australia', value: 'SA' },
  { label: 'Tasmania', value: 'TAS' },
  { label: 'Victoria', value: 'VIC' },
  { label: 'Western Australia', value: 'WA' },
  { label: 'Australian Capital Territory', value: 'ACT' },
  { label: 'Northern Territory', value: 'NT' },
];

export default Constants;
