import { toast } from '@postscript/components';
import produce from 'immer';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { useQueryClient } from 'react-query';

import { api } from 'controllers/network/apiClient';
import {
  StripePaymentMethod,
  StripeSetupIntent,
  StripeSetupIntentStatuses,
} from '../common/types';
import { paymentMethodsQueryKey } from '../modules/payments/hooks/usePaymentMethods';
import { useGetAvailablePaymentMethods } from './useBilling';

// Default payment method management steps
// Re-configured and exported as context state
const defaultSteps = {
  management: {
    heading: 'Manage payment methods',
    previousStep: null,
  },
  typeChoice: {
    heading: 'Add payment method',
    previousStep: 'management',
  },
  deleteConfirmation: {
    heading: 'Delete payment method',
    previousStep: 'management',
  },
  stripeCard: {
    heading: 'Card details',
    previousStep: 'typeChoice',
  },
  stripeAch: {
    heading: 'ACH accountholder details',
    previousStep: 'typeChoice',
  },
  stripeAchMandate: {
    heading: 'Review',
    previousStep: 'achAccountholderForm',
  },
};

export type PaymentMethodSteps = keyof typeof defaultSteps;

interface ACHDetails {
  clientSecret: string;
  name: string;
  email: string;
  bankName: string;
  last4: string;
  routingNumber: string;
}

export interface PaymentMethodStepData {
  paymentMethodToDeleteId?: string;
  achDetails?: ACHDetails;
}

interface PaymentMethod extends StripePaymentMethod {
  setupIntentStatus?: StripeSetupIntentStatuses;
}

const UPDATE_STEPS = 'UPDATE_STEPS';
const UPDATE_STEP = 'UPDATE_STEP';
const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS';
const UPDATE_DEFAULT_PAYMENT_METHOD_ID = 'UPDATE_DEFAULT_PAYMENT_METHOD_ID';
const UPDATE_UNVERIFIED_PAYMENT_METHODS = 'UPDATE_UNVERIFIED_PAYMENT_METHODS';
const UPDATE_IS_LOADING = 'UPDATE_IS_LOADING';

interface UpdateSteps {
  type: typeof UPDATE_STEPS;
  steps: typeof defaultSteps;
}

interface UpdateStep {
  type: typeof UPDATE_STEP;
  step: PaymentMethodSteps;
  data: PaymentMethodStepData;
}

interface UpdatePaymentMethods {
  type: typeof UPDATE_PAYMENT_METHODS;
  paymentMethods: PaymentMethod[];
}

interface UpdateDefaultPaymentMethodId {
  type: typeof UPDATE_DEFAULT_PAYMENT_METHOD_ID;
  defaultPaymentMethodId: string;
}

interface UpdateUnverifiedMethods {
  type: typeof UPDATE_UNVERIFIED_PAYMENT_METHODS;
  unverifiedPaymentMethods: PaymentMethod[];
}

interface UpdateIsLoading {
  type: typeof UPDATE_IS_LOADING;
  isLoading: boolean;
}

type ReducerAction =
  | UpdateSteps
  | UpdateStep
  | UpdatePaymentMethods
  | UpdateDefaultPaymentMethodId
  | UpdateUnverifiedMethods
  | UpdateIsLoading;

interface State {
  steps: typeof defaultSteps;
  step: PaymentMethodSteps;
  stepData: PaymentMethodStepData;
  paymentMethods: PaymentMethod[];
  defaultPaymentMethodId: string | null;
  unverifiedPaymentMethods: PaymentMethod[];
  isLoading: boolean;
  updateStep: (step: PaymentMethodSteps, data?: PaymentMethodStepData) => void;
  getPaymentMethods: () => Promise<void>;
  getUnverifiedPaymentMethods: () => Promise<void>;
  setDefaultPaymentMethod: (id: string) => Promise<void>;
  deletePaymentMethod: (id: string) => Promise<void>;
}

const initialState: State = {
  steps: defaultSteps,
  step: 'management',
  stepData: {},
  paymentMethods: [],
  defaultPaymentMethodId: null,
  unverifiedPaymentMethods: [],
  isLoading: true,
  updateStep: async () => undefined,
  getPaymentMethods: async () => undefined,
  getUnverifiedPaymentMethods: async () => undefined,
  setDefaultPaymentMethod: async () => undefined,
  deletePaymentMethod: async () => undefined,
};

const reducerFn = (draft: State, action: ReducerAction) => {
  switch (action.type) {
    case UPDATE_STEPS:
      draft.steps = action.steps;
      break;
    case UPDATE_STEP:
      draft.step = action.step;
      draft.stepData = action.data;
      break;
    case UPDATE_PAYMENT_METHODS:
      draft.paymentMethods = action.paymentMethods;
      break;
    case UPDATE_DEFAULT_PAYMENT_METHOD_ID:
      draft.defaultPaymentMethodId = action.defaultPaymentMethodId;
      break;
    case UPDATE_UNVERIFIED_PAYMENT_METHODS:
      draft.unverifiedPaymentMethods = action.unverifiedPaymentMethods;
      break;
    case UPDATE_IS_LOADING:
      draft.isLoading = action.isLoading;
      break;
    default:
      throw new Error('Unsupported action type dispatched.');
  }
};

export const PaymentMethodsContext = createContext(initialState);
export const usePaymentMethods = (): State => useContext(PaymentMethodsContext);

interface Props {
  children: JSX.Element;
  initialStep?: PaymentMethodSteps;
}

export const PaymentMethodsProvider = ({
  initialStep,
  children,
}: Props): JSX.Element | null => {
  const queryClient = useQueryClient();

  const reducer = produce(reducerFn);
  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    (initialState) => ({
      ...initialState,
      step: initialStep || initialState.step,
    }),
  );
  const { data: availablePaymentMethods } = useGetAvailablePaymentMethods();

  function updateSteps(): void {
    const multiplePaymentMethodsAvailable =
      Object.values(availablePaymentMethods || {}).filter((v) => v).length > 1;

    const steps = {
      ...defaultSteps,
      stripeAch: {
        ...defaultSteps.stripeAch,
        previousStep: multiplePaymentMethodsAvailable
          ? ('typeChoice' as PaymentMethodSteps)
          : ('management' as PaymentMethodSteps),
      },
    };

    dispatch({
      type: UPDATE_STEPS,
      steps,
    });
  }

  function updateStep(
    step: PaymentMethodSteps,
    data: PaymentMethodStepData = {},
  ): void {
    dispatch({
      type: UPDATE_STEP,
      step,
      data,
    });
  }

  async function getPaymentMethods(): Promise<void> {
    try {
      const { paymentMethods, defaultPaymentMethodId } = await api.get(
        '/v2/billing/payments/stripe/payment_methods',
      );

      dispatch({
        type: UPDATE_PAYMENT_METHODS,
        paymentMethods,
      });

      dispatch({
        type: UPDATE_DEFAULT_PAYMENT_METHOD_ID,
        defaultPaymentMethodId,
      });
    } catch (error) {
      toast.error(error as string);
    }
  }

  async function getUnverifiedPaymentMethods(): Promise<void> {
    try {
      const { setupIntents }: { setupIntents: StripeSetupIntent[] } =
        await api.get('/v2/billing/payments/stripe/setup_intents');

      const unverifiedPaymentMethods =
        setupIntents
          ?.filter(
            ({ status, paymentMethod }) =>
              ['processing', 'requires_action'].includes(status) &&
              paymentMethod?.usBankAccount,
          )
          .map(({ paymentMethod, status }) => ({
            ...paymentMethod,
            setupIntentStatus: status,
          })) || [];

      dispatch({
        type: UPDATE_UNVERIFIED_PAYMENT_METHODS,
        unverifiedPaymentMethods,
      });
    } catch (error) {
      toast.error(error as string);
    }
  }

  async function setDefaultPaymentMethod(id: string): Promise<void> {
    await api.put('/v2/billing/payments/stripe/payment_methods/default', {
      id,
    });

    dispatch({
      type: UPDATE_DEFAULT_PAYMENT_METHOD_ID,
      defaultPaymentMethodId: id,
    });

    queryClient.invalidateQueries(paymentMethodsQueryKey);
  }

  async function deletePaymentMethod(id: string): Promise<void> {
    await api.delete(`/v2/billing/payments/stripe/payment_methods/${id}`);

    queryClient.invalidateQueries(paymentMethodsQueryKey);
  }

  useEffect(() => {
    updateSteps();
  }, [availablePaymentMethods]);

  useEffect(() => {
    (async () => {
      await getPaymentMethods();

      dispatch({
        type: UPDATE_IS_LOADING,
        isLoading: false,
      });
    })();

    getUnverifiedPaymentMethods();
  }, []);

  return (
    <PaymentMethodsContext.Provider
      value={{
        ...state,
        updateStep,
        getPaymentMethods,
        getUnverifiedPaymentMethods,
        setDefaultPaymentMethod,
        deletePaymentMethod,
      }}
    >
      {children}
    </PaymentMethodsContext.Provider>
  );
};
