import styled from 'styled-components';

import { Icon, IconSet, Layout } from '@postscript/components';
import { useAddResponseFeedback } from 'components/conversations/hooks/useResponses';
import { MESSAGE_SUBTYPES } from '../constants';
import { StyledMetadata } from '../styles/message';
import { Message } from '../types';

interface MessageMetadataProps {
  message: Message;
  showTimestamp?: boolean;
}

const StyledThumb = styled(Icon)<{
  direction: 'up' | 'down';
  disabled?: boolean;
}>`
  color: ${(props) =>
    props.disabled ? 'var(--text-color-dim)' : 'var(--text-color)'};
  margin-right: ${(props) =>
    props.direction === 'up' ? 'var(--spacing-1)' : '0'};

  &:hover {
    color: ${(props) =>
      props.disabled
        ? 'var(--text-color-dim)'
        : props.direction === 'up'
        ? 'var(--green-6)'
        : 'var(--red-6)'};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

interface MessageFeedbackProps {
  message: Message;
}

const MessageFeedback = ({ message }: MessageFeedbackProps) => {
  const { mutate: addResponseFeedback, isLoading: isSubmittingFeedback } =
    useAddResponseFeedback();
  // eslint-disable-next-line camelcase
  const onClickHandler = (feedback_type: 'positive' | 'negative') => {
    return () => {
      addResponseFeedback({
        messageId: message.sent_message_id,
        feedback_type,
        message: message.text,
      });
    };
  };

  return (
    <span>
      <StyledThumb
        direction="up"
        component={IconSet.ThumbsUp}
        size="small"
        onClick={onClickHandler('positive')}
        disabled={isSubmittingFeedback}
      />
      <StyledThumb
        direction="down"
        component={IconSet.ThumbsDown}
        size="small"
        onClick={onClickHandler('negative')}
        disabled={isSubmittingFeedback}
      />
    </span>
  );
};

const MessageMetadata = ({
  message,
  showTimestamp = true,
}: MessageMetadataProps) => {
  const isShopperMessage =
    message.direction === 'outgoing' &&
    message.subtype === MESSAGE_SUBTYPES.SHOPPER;

  return (
    <StyledMetadata
      messageType={message.type}
      incoming={message.direction === 'incoming'}
    >
      {isShopperMessage ? (
        <Layout align="justify">
          <MessageFeedback message={message} />
          <span>
            Sent by Shopper
            {showTimestamp && ` • ${message.createdAt.format('HH:mm')}`}
          </span>
        </Layout>
      ) : (
        showTimestamp && message.createdAt.format('HH:mm')
      )}
    </StyledMetadata>
  );
};

export default MessageMetadata;
