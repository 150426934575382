import { ITC_PLAN_ID } from 'components/billing/common/constants';
import { useGetCurrentPlan } from 'components/billing/context/useBilling';
import { useHasProductAddOn } from 'components/billing/modules/plans/hooks/useHasProductAddOn';
import { useProductTrialStatus } from 'components/billing/modules/plans/hooks/useProductTrialStatus';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { useTotalSubscribers } from 'hooks/useTotalSubscribers';

/** Provide information on the billing status of Infinity Testing. */
export const useInfinityTestingBillingStatus = () => {
  const { hasLabsFlag } = usePSLabs();
  const { data: subscribersTotal = 1 } = useTotalSubscribers();
  const { data: currentPlan } = useGetCurrentPlan();

  const isEligible =
    hasLabsFlag('self_serve_infinity_testing_override') ||
    (hasLabsFlag('self_serve_infinity_testing') &&
      subscribersTotal >= 10_000 &&
      ['Enterprise', 'Professional'].includes(
        currentPlan?.package?.name || '',
      ));

  const hasProduct = useHasProductAddOn('MARKETING_AI', 'MARKETING_AI');
  const {
    hasExpiredProductFreeTrial,
    onProductFreeTrial,
    trialProductPlanId,
    trialEndDate,
  } = useProductTrialStatus('MARKETING_AI', ITC_PLAN_ID);

  return {
    /** Is eligible for an Infinity Testing plan */
    isEligible,

    /** Has an infinity Testing plan */
    hasProduct,

    /** Is eligible for a free trial of Infinity Testing */
    trialAvailable: isEligible && !hasProduct && !hasExpiredProductFreeTrial,

    /** Is currently on a trial of Infinity Testing */
    trialOngoing: onProductFreeTrial,

    /** The end date of the trial */
    trialEndDate,

    /** Has an expired trial of Infinity Testing */
    trialExpired: hasExpiredProductFreeTrial,

    /** The ID of the trial product plan */
    trialProductPlanId,
  };
};
